import * as React from 'react';
import { Button, createTestId, useClickOutside } from '@partner-global-ui/components';
import './SplitButton.scss';
import cn from 'classnames';

const { useRef } = React;

export interface SplitButtonOptionProps {
  /* disables option if set to true */
  disabled: boolean;
  /* action performed when button is clicked */
  onClick: () => void;
  /* option text */
  label: string;
}
export interface SplitButtonProps {
  /* A set of components to render within the primary button */
  children?: React.ReactNode;
  /* class name of component. */
  className?: string;
  /* disabled for component */
  disabled?: boolean;
  /* id of component. */
  id?: string;
  /* On click handler for primary button */
  onClick: () => void;
  /* props for all options */
  options: Array<SplitButtonOptionProps>;
}

const SplitButton: React.FunctionComponent<SplitButtonProps> = ({
  children,
  className,
  disabled,
  id,
  onClick,
  options,
}) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useClickOutside(componentRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  const handleOptionClick = (option: SplitButtonOptionProps) => {
    option.onClick();
    setShowOptions(false);
  };

  const wrapperClasses = cn('split-section-container', { [`${className}`]: className });

  return (
    <div id={id} className={wrapperClasses} ref={componentRef}>
      <div className="split-button-container">
        <Button
          primary
          id="split-button-primary"
          data-testid={createTestId('split-button-primary', id)}
          className="split-button-primary"
          disabled={disabled}
          onClick={onClick}
          type="button"
        >
          {children}
        </Button>
        <Button
          primary
          id="split-button-dropdown"
          data-testid={createTestId('split-button-dropdown', id)}
          className="split-button-dropdown"
          disabled={disabled}
          onClick={() => setShowOptions(!showOptions)}
          type="button"
        >
          <i
            className="glyphicon glyphicon-chevron-down"
            data-testid="glyphicon-chevron-down"
          />
        </Button>
      </div>
      {showOptions && (
        <div
          className="options-container"
          data-testid={createTestId('options-container', id)}
        >
          {options.map((option: SplitButtonOptionProps, index: number) => (
            <button
              key={`options-${index}`} // eslint-disable-line react/no-array-index-key
              className="options"
              data-testid={createTestId(`options-${index}`, id)}
              disabled={option.disabled}
              onClick={() => handleOptionClick(option)}
              tabIndex={0}
              type="button"
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SplitButton;
