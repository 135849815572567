export default {
  CAMPAIGNS_RECOMMENDATIONS: 'CAMPAIGNS_RECOMMENDATIONS',
  AUTOMERCH: 'AUTOMERCH',
  CREATIVE: 'CREATIVE',
  CHANNELS: 'CHANNELS',
  ATTRIBUTES: 'ATTRIBUTES',
  SEGMENTS: 'SEGMENTS',
  EXPERIMENTATION: 'EXPERIMENTATION',
  PROGRAMMATIC: 'PROGRAMMATIC',
};
