import * as React from 'react';
import {
  BaseMenuItem,
  DropdownMenu,
  MenuInformation,
  SelectedMenuItem,
} from '@partner-global-ui/components/dist/LocalNavigation/local-navigation';
import { useHistory } from 'react-router-dom';
import {
  LocalNavigation, Tooltip, ALERT_ACTIONS, AlertContext, STATUS, Alert,
} from '@partner-global-ui/components';
import rolesConst from '../../constants/roles';
import domainsConst from '../../constants/domains';
import './Header.scss';

const {
  useState, useEffect, useRef, useContext,
} = React;

export interface HeaderProps {
  /** Current user's roles */
  userRoles: Array<{ roleName?: string, domains?: Array<string> }>,
  /** The history object. It's used to properly redirect the links.
   *  By default, it uses useHistory(). */
  history?: { push: (arg: string) => any },
  /** Whether the navigation should be fixed at top position */
  fixed?: boolean,
  /** Text to be shown on the right-side of the bar */
  tooltip?: string,
  /** Whether to show confirmation prompt before proceeding */
  showPrompt?: boolean,
  /** The title of the confirmation prompt */
  promptTitle?: string,
  /** The message on the confirmation prompt */
  promptContent?: string,
  /** Label for the primary button */
  promptPrimaryButtonLabel?: string,
  /** Label for the secondary button */
  promptSecondaryButtonLabel?: string,
  /** Show/hide Recommendation */
  enableRecommendation?: boolean,
  /** Show/hide Automerch */
  enableAutoMerch?: boolean,
  /** Show/hide Creative Redesign */
  enableCreativeRedesign?: boolean,
  /** Show/hide Channel Manager */
  enableChannelManager?: boolean,
  /** Show/hide Segment Manager */
  enableSegmentManager?: boolean,
  /** Show/hide Attribute Manager */
  enableAttributeManager?: boolean,
  /** Show/hide Admin */
  enableAdmin?: boolean,
  /** Show/hide Experimentation */
  enableExperimentation?: boolean,
  /** Show/hide Programmatic */
  enableProgrammatic?: boolean,
}

export interface HeaderSelectedMenuItem extends SelectedMenuItem {
  parentPath?: string;
  menuInformation: HeaderMenuInformation;
}

export interface HeaderMenuInformation extends MenuInformation {
  alwaysIncludeParentPath?: boolean,
}

export interface HeaderDropdownMenu extends DropdownMenu {
  menuInformation: HeaderMenuInformation;
  subMenuItems?: HeaderBaseMenuItem[];
}

export interface HeaderBaseMenuItem extends BaseMenuItem {
  menuInformation: HeaderMenuInformation;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  userRoles,
  history: providedHistory = null,
  fixed = true,
  tooltip = '',
  showPrompt = false,
  promptTitle = 'Leave this page?',
  promptContent = 'Are you sure you want to leave this page? Leaving would result in lost changes. Please make sure you save your work before doing so.',
  promptPrimaryButtonLabel = 'Exit',
  promptSecondaryButtonLabel = 'Go Back',
  enableRecommendation = false,
  enableAutoMerch = false,
  enableCreativeRedesign = false,
  enableChannelManager = false,
  enableSegmentManager = false,
  enableAttributeManager = false,
  enableAdmin = false,
  enableProgrammatic = false,
  enableExperimentation = false,
}) => {
  const history = providedHistory || useHistory();
  const { dispatch } = useContext(AlertContext);
  const headerTooltipWrapper = useRef<HTMLDivElement>(null);
  const roleNames = userRoles.map((role) => role.roleName);
  const allDomains: string[] = [];
  userRoles.forEach(({ domains = [] }) => {
    domains.forEach((domain: string) => {
      allDomains.push(domain);
    });
  });
  const isSuperAdmin = roleNames.includes(rolesConst.SUPER_ADMIN);
  const generateMenuItems = () => {
    const menuItems: HeaderDropdownMenu[] = [];
    if (isSuperAdmin
      || allDomains.includes(domainsConst.CAMPAIGNS_RECOMMENDATIONS)
      || allDomains.includes(domainsConst.AUTOMERCH)
      || allDomains.includes(domainsConst.PROGRAMMATIC)) {
      const menuItem: HeaderDropdownMenu = {
        menuInformation: { label: 'Campaigns', value: '' },
        subMenuItems: [],
      };
      if (enableRecommendation
        && (isSuperAdmin || allDomains.includes(domainsConst.CAMPAIGNS_RECOMMENDATIONS))) {
        menuItem?.subMenuItems?.push({ menuInformation: { label: 'Recommendations', value: 'campaigns' } });
      }
      if (enableAutoMerch
        && (isSuperAdmin || allDomains.includes(domainsConst.AUTOMERCH))) {
        menuItem?.subMenuItems?.push({ menuInformation: { label: 'Merchandise', value: 'merchandising' } });
      }
      if (enableProgrammatic
        && (isSuperAdmin || allDomains.includes(domainsConst.PROGRAMMATIC))) {
        menuItem?.subMenuItems?.push({ menuInformation: { label: 'Programmatic', value: 'programmatic' } });
      }
      if (enableRecommendation || enableAutoMerch || enableProgrammatic) {
        menuItems.push(menuItem);
      }
    }
    if (enableCreativeRedesign
      && (isSuperAdmin || allDomains.includes(domainsConst.CREATIVE))) {
      menuItems.push({ menuInformation: { label: 'CET', value: 'cet' } });
    }
    if (enableChannelManager
      && (isSuperAdmin || allDomains.includes(domainsConst.CHANNELS))) {
      menuItems.push({ menuInformation: { label: 'Channels', value: 'channels' } });
    }
    if (isSuperAdmin
      || allDomains.includes(domainsConst.ATTRIBUTES)
      || allDomains.includes(domainsConst.SEGMENTS)) {
      const menuItem: HeaderDropdownMenu = {
        menuInformation: { label: 'Audience', value: 'audience' },
        subMenuItems: [],
      };
      if (enableSegmentManager
        && (isSuperAdmin || allDomains.includes(domainsConst.SEGMENTS))) {
        menuItem?.subMenuItems?.push({ menuInformation: { label: 'Segments', value: 'segments' } });
      }
      if (enableAttributeManager
        && (isSuperAdmin || allDomains.includes(domainsConst.ATTRIBUTES))) {
        menuItem?.subMenuItems?.push({ menuInformation: { label: 'Attributes', value: 'attributes' } });
      }
      if (enableSegmentManager || enableAttributeManager) {
        menuItems.push(menuItem);
      }
    }
    if (enableExperimentation
      && (isSuperAdmin || allDomains.includes(domainsConst.EXPERIMENTATION))) {
      const menuItem: HeaderDropdownMenu = {
        menuInformation: { label: 'Experimentation', value: 'experimentation' },
        subMenuItems: [],
      };
      menuItem?.subMenuItems?.push({ menuInformation: { label: 'Experiments', value: 'experiments' } });
      menuItem?.subMenuItems?.push({ menuInformation: { label: 'Metric Management', value: 'metrics' } });
      menuItems.push(menuItem);
    }
    if (isSuperAdmin && enableAdmin) {
      menuItems.push({
        menuInformation: { label: 'Admin', value: 'admin' },
        subMenuItems: [
          {
            menuInformation: { label: 'Users', value: 'users', alwaysIncludeParentPath: true },
          },
          {
            menuInformation: { label: 'Application Configs', value: 'configs', alwaysIncludeParentPath: true },
          },
        ],
      });
    }
    return menuItems;
  };
  const menuItems = generateMenuItems();
  const [selectedMenuItem, setSelectedMenuItem] = useState<HeaderSelectedMenuItem>({
    menuInformation: { label: '', value: '', testId: '' },
  });
  const { protocol, host, href } = window.location;

  const showExitPrompt = (primaryAction: (() => void) | undefined) => {
    dispatch({
      type: ALERT_ACTIONS.SHOW,
      payload: {
        component: (
          <Alert
            id="exit-prompt-modal"
            status={STATUS.WARNING}
            title={promptTitle}
            content={promptContent}
            primaryButtonLabel={promptPrimaryButtonLabel}
            secondaryButtonLabel={promptSecondaryButtonLabel}
            primaryAction={primaryAction}
          />
        ),
      },
    });
  };

  const openSelectedItem = (
    isMetaOrCtrlKeyPressed: boolean,
    path: string,
    sameDropdownAndParentPath: boolean,
  ) => {
    if (isMetaOrCtrlKeyPressed) {
      window.open(`${protocol}//${host}/${path}`, '_blank');
    } else if (sameDropdownAndParentPath) {
      history.push(`/${path}`);
    } else {
      (window as Window).location = `${protocol}//${host}/${path}`;
    }
  };

  const handleOnSelect = (
    isMetaOrCtrlKeyPressed: boolean,
    selected: HeaderSelectedMenuItem,
  ) => {
    const {
      menuItemIndex = -1,
      dropdownIndex = -1,
      menuInformation: { value, alwaysIncludeParentPath },
    } = selected;
    const selectedParentPath = menuItemIndex !== -1
      ? menuItems[dropdownIndex].menuInformation.value
      : value;
    const currentParentPath = selectedMenuItem.parentPath;
    const sameDropdownAndParentPath = selectedMenuItem.dropdownIndex === dropdownIndex
      && !!selectedParentPath && currentParentPath === selectedParentPath;
    const path = sameDropdownAndParentPath
      ? (alwaysIncludeParentPath || isMetaOrCtrlKeyPressed ? `${selectedParentPath}/${value}` : `${value}`)
      : (value === selectedParentPath || !selectedParentPath ? value : `${selectedParentPath}/${value}`);
    openSelectedItem(isMetaOrCtrlKeyPressed, path, sameDropdownAndParentPath);
    if (!isMetaOrCtrlKeyPressed) {
      setSelectedMenuItem({
        ...selected,
        parentPath: selectedParentPath,
      });
    }
  };

  const checkForPrompt = (
    event: { metaKey: boolean; ctrlKey: boolean; },
    selected: SelectedMenuItem,
  ) => {
    const isMetaOrCtrlKeyPressed = event.metaKey || event.ctrlKey;
    if (!isMetaOrCtrlKeyPressed && showPrompt) {
      showExitPrompt(() => handleOnSelect(isMetaOrCtrlKeyPressed, selected));
    } else {
      handleOnSelect(isMetaOrCtrlKeyPressed, selected);
    }
  };

  const getInitialSelectedMenuItem = () => {
    const flattenMenuItems: Array<HeaderSelectedMenuItem> = [];

    menuItems.forEach((menuItem, dropdownIndex) => {
      const { subMenuItems = [] } = menuItem;
      if (subMenuItems.length === 0) {
        flattenMenuItems.push({
          ...menuItem,
          dropdownIndex,
        });
      } else {
        subMenuItems.forEach((subMenuItem, menuItemIndex) => {
          flattenMenuItems.push({
            ...subMenuItem,
            menuItemIndex,
            dropdownIndex,
            parentPath: menuItem.menuInformation.value,
          });
        });
      }
    });

    return flattenMenuItems.find((menuItem) => {
      const { menuInformation: { value }, parentPath } = menuItem;
      const path = !parentPath ? value : `${parentPath}/${value}`;
      return href.startsWith(`${protocol}//${host}/${path}`);
    }) || { menuInformation: { value: '', label: '' } };
  };

  useEffect(() => {
    setSelectedMenuItem(getInitialSelectedMenuItem());
  }, [enableRecommendation, enableAutoMerch,
    enableCreativeRedesign, enableChannelManager, enableSegmentManager,
    enableAttributeManager, enableExperimentation, enableAdmin,
    enableProgrammatic]);

  return (
    <>
      <div
        className={`iptools-common-ui-header ${fixed ? 'fixed' : ''}`}
        data-testid="iptools-common-ui-header"
      >
        <LocalNavigation
          id="iptools-header"
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          onSelect={checkForPrompt}
          onToggle={() => {}}
        />
        {tooltip && (
          <div className="header-tooltip">
            <Tooltip
              content={<span className="tooltip-content">{tooltip}</span>}
              shortCopy
              anchor={headerTooltipWrapper}
            />
          </div>
        )}
      </div>
      <div
        className="header-tooltip-wrapper"
        ref={headerTooltipWrapper}
      />
    </>
  );
};

Header.displayName = 'Header';

export default Header;
