import * as React from 'react';
import { useClickOutside } from '@partner-global-ui/components';
import './Selector.scss';
import '../../Glyphicons.scss';

const { useEffect, useRef, useState } = React;

export interface Option {
  label: string,
  value: string | number,
}

export interface SelectorProps {
  /** Placeholder text if default */
  placeholder?: string;
  /** List of Options */
  options: Option[];
  /** Default Option on render */
  defaultOption?: Option;
  /** onSelect callback */
  onSelect: (selection: Option) => void;
  /** ID for component */
  id?: string;
}

const Selector: React.FC<SelectorProps> = ({
  placeholder, defaultOption, options, onSelect, id,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
      onSelect(defaultOption);
    }
  }, [defaultOption]);

  const handleClickOutside = () => {
    if (showOptions) {
      setShowOptions(false);
    }
  };

  useClickOutside(optionsContainerRef, handleClickOutside);

  const handleOptionOnClick = (option: Option) => {
    onSelect(option);
    setSelectedOption(option);
    setShowOptions(false);
  };

  const renderOptions = () => (
    <>
      <span className="triangle-with-shadow" />

      <div
        className="selector-options-container"
        data-testid="selector-options-container"
        ref={optionsContainerRef}
      >

        {options.map((option) => (
          <div
            role="button"
            tabIndex={0}
            key={option.value}
            className="option-row"
            data-testid={`option-row-${option.label}`}
            onClick={() => handleOptionOnClick(option)}
            onKeyDown={() => {}}
          >

            <span className="option-text">{option.label}</span>

          </div>
        ))}

      </div>
    </>
  );

  return (
    <span
      id={id}
      className="selector-container"
      data-testid="selector-container"
    >

      <span
        role="button"
        tabIndex={0}
        className="selected-text-and-glyphicon"
        data-testid="selected-text-and-glyphicon"
        onClick={() => setShowOptions(!showOptions)}
        onKeyDown={() => {}}
      >

        <span className="selected-text" data-testid="selected-text">
          {selectedOption ? selectedOption.label : placeholder}
        </span>

        <span className="tooltip-icon">
          <i
            className="glyphicon glyphicon-chevron-down"
            data-testid="glyphicon-chevron-down"
          />
        </span>

      </span>

      {showOptions && renderOptions()}

    </span>
  );
};

Selector.displayName = 'Selector';

export default Selector;
