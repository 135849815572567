import * as React from 'react';
import {
  DragDropContext, Droppable, Draggable, DragUpdate,
} from 'react-beautiful-dnd';
import { Icon, useClickOutside } from '@partner-global-ui/components';
import './ColumnSelector.scss';

const { useRef } = React;

export interface Column {
  displayName: string,
  visible: boolean,
  sortKey?: string,
  disabled: boolean,
}

export interface ColumnRecord {
  [key: string]: Column
}

export interface ColumnSelectorProps {
  /** The columns to be managed */
  columns: ColumnRecord,
  /** Header text */
  headerText?: string,
  /** Subheader text */
  subheaderText?: string,
  /** onSelect callback */
  onSelect: (cols: ColumnRecord) => void,
}

const ColumnSelector: React.FunctionComponent<ColumnSelectorProps> = ({
  columns,
  headerText = 'Manage Columns',
  subheaderText = 'Remove and reorder to customize your view',
  onSelect,
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useClickOutside(componentRef, () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  });

  const reorder = (list:Array<[string, Column]>, startIndex:number, endIndex:number) => {
    const resultArray = Array.from(list);
    const [removed] = resultArray.splice(startIndex, 1);
    resultArray.splice(endIndex, 0, removed);

    return resultArray;
  };
  const onDragEnd = (result:DragUpdate) => {
    const colsEntries = Object.entries(columns);
    const { source, destination } = result;
    // dropped outside the list or above disabled/frozen columns
    if (!destination || colsEntries[destination.index][1].disabled) {
      return;
    }

    const newColumns = reorder(
      colsEntries,
      source.index,
      destination.index,
    );
    // eslint-disable-next-line consistent-return
    return onSelect(Object.fromEntries(newColumns));
  };
  const renderColumnSelectorList = () => (
    <div
      className="column-selector-table-menu"
      data-testid="column-selector-table-menu"
    >
      <div
        className="column-selector-table-menu-header"
      >
        <span
          data-testid="column-selector-header-text"
        >
          {headerText}
        </span>
        <div // eslint-disable-line jsx-a11y/no-static-element-interactions
          className="menu-close"
          data-testid="column-selector-close-button"
          onClick={() => setIsExpanded(false)}
          onKeyDown={() => {}}
        >
          <Icon>
            ico-x
          </Icon>
        </div>
      </div>
      <div
        className="sub-header"
        data-testid="column-selector-subheader-text"
      >
        {subheaderText}
      </div>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable
          droppableId="droppable"
        >
          {(provided) => (
            <div
              {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
              ref={provided.innerRef}
            >
              {Object.entries(columns).map(([key, column], index) => {
                const { displayName, disabled, visible } = column;
                return (
                  <Draggable
                    key={displayName}
                    draggableId={displayName}
                    index={index}
                  >
                    {(providedAgain) => (
                      <div
                        key={key}
                        data-testid={`column-selector-menu-row-${key}`}
                        className={`column-selector-menu-row ${disabled ? 'disabled' : ''}`}
                        ref={providedAgain.innerRef}
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...providedAgain.draggableProps}
                        {...providedAgain.dragHandleProps}
                        /* eslint-enable react/jsx-props-no-spreading */
                        draggable
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            onSelect({
                              ...columns,
                              [key]: {
                                ...column,
                                visible: (e.target as HTMLInputElement).checked,
                              },
                            });
                          }}
                          checked={visible}
                          data-testid={`column-selector-${key}-checkbox`}
                          disabled={disabled}
                        />
                        <span
                          className="column-selector-display-name"
                          data-testid={`column-selector-${key}-display-name`}
                        >
                          {displayName}
                        </span>
                        <Icon
                          className={`drag-icon ${disabled ? 'disabled' : ''}`}
                          id={`column-selector-${key}-drag`}
                        >
                          menu
                        </Icon>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  return (
    <div ref={componentRef}>
      <i // eslint-disable-line jsx-a11y/control-has-associated-label
        role="button"
        tabIndex={0}
        data-testid="column-selector-table-icon"
        className={`column-selector-icon ${isExpanded ? 'column-selector-icon-active' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={() => {
        }}
      />
      {isExpanded && renderColumnSelectorList()}
    </div>
  );
};

// Component name for use in static builds
ColumnSelector.displayName = 'ColumnSelector';

export default ColumnSelector;
