import * as React from 'react';
import { ModalContainer } from '@partner-global-ui/components';
import CommonModalRunner from './CommonModalRunner';
import { CommonModalProps } from './CommonModalProps';

const CommonModal: React.FunctionComponent<CommonModalProps> = ({
  className = '',
  id = '',
  show,
  onClose,
  title,
  onSecondaryClick = () => {},
  onPrimaryClick = () => {},
  primaryLabel,
  primaryDisabled = false,
  secondaryLabel = '',
  secondaryDisabled = false,
  content,
  hideCancelButton = false,
  cancelLabel = 'Cancel',
  height = 0,
  width = 1080,
  dependencies = [],
}) => (
  <ModalContainer onClose={onClose}>
    <CommonModalRunner
      cancelLabel={cancelLabel}
      className={className}
      content={content}
      dependencies={dependencies}
      height={height}
      hideCancelButton={hideCancelButton}
      id={id}
      primaryDisabled={primaryDisabled}
      primaryLabel={primaryLabel}
      onClose={onClose}
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      secondaryDisabled={secondaryDisabled}
      secondaryLabel={secondaryLabel}
      show={show}
      title={title}
      width={width}
    />
  </ModalContainer>
);

export default CommonModal;
