import * as React from 'react';
import {
  BaseMenuItem,
  DropdownMenu,
  MenuInformation,
  SelectedMenuItem,
} from '@partner-global-ui/components/dist/LocalNavigation/local-navigation';
import { useHistory } from 'react-router-dom';
import {
  LocalNavigation, Tooltip, ALERT_ACTIONS, AlertContext, STATUS, Alert,
} from '@partner-global-ui/components';
import './NavBar.scss';
import { isLocal } from '../../utils/appUtils';

const {
  useState, useEffect, useRef, useContext,
} = React;

export interface HeaderProps {
  /** The history object. It's used to properly redirect the links.
   *  By default, it uses useHistory(). */
  history?: { push: (arg: string) => any },
  /** Whether the navigation should be fixed at top position */
  fixed?: boolean,
  /** Text to be shown on the right-side of the bar */
  tooltip?: string,
  /** Whether to show confirmation prompt before proceeding */
  showPrompt?: boolean,
  /** The title of the confirmation prompt */
  promptTitle?: string,
  /** The message on the confirmation prompt */
  promptContent?: string,
  /** Label for the primary button */
  promptPrimaryButtonLabel?: string,
  /** Label for the secondary button */
  promptSecondaryButtonLabel?: string,
  /** User's Okta JWT Token */
  token: string,
  /** Optional source URL the menu items should be retrieved from.
   *  This is mainly for testing purpose. */
  sourceUrl?: string,
}

export interface HeaderDropdownMenu extends DropdownMenu {
  menuInformation: MenuInformation;
  subMenuItems?: HeaderBaseMenuItem[];
}

export interface HeaderBaseMenuItem extends BaseMenuItem {
  menuInformation: MenuInformation;
}

export interface RawMenuItem {
  label: string,
  path: string,
  subMenuItems: RawMenuItem[],
}

const Header: React.FunctionComponent<HeaderProps> = ({
  history: providedHistory = null,
  fixed = true,
  tooltip = '',
  showPrompt = false,
  promptTitle = 'Leave this page?',
  promptContent = 'Are you sure you want to leave this page? Leaving would result in lost changes. Please make sure you save your work before doing so.',
  promptPrimaryButtonLabel = 'Exit',
  promptSecondaryButtonLabel = 'Go Back',
  token,
  sourceUrl,
}) => {
  const history = providedHistory || useHistory();
  const { dispatch } = useContext(AlertContext);
  const [rawMenuItems, setRawMenuItems] = React.useState<RawMenuItem[]>([]);
  const headerTooltipWrapper = useRef<HTMLDivElement>(null);
  const menuItems: HeaderDropdownMenu[] = rawMenuItems.map((menuItem) => ({
    menuInformation: { label: menuItem.label, value: menuItem.path },
    subMenuItems: menuItem?.subMenuItems?.map((subMenuItem) => ({
      menuInformation: { label: subMenuItem.label, value: subMenuItem.path },
    })) || [],
  }));
  const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedMenuItem>({
    menuInformation: { label: '', value: '', testId: '' },
  });
  const { protocol, host, href } = window.location;

  const showExitPrompt = (primaryAction: (() => void) | undefined) => {
    dispatch({
      type: ALERT_ACTIONS.SHOW,
      payload: {
        component: (
          <Alert
            id="exit-prompt-modal"
            status={STATUS.WARNING}
            title={promptTitle}
            content={promptContent}
            primaryButtonLabel={promptPrimaryButtonLabel}
            secondaryButtonLabel={promptSecondaryButtonLabel}
            primaryAction={primaryAction}
          />
        ),
      },
    });
  };

  const openSelectedItem = (
    isMetaOrCtrlKeyPressed: boolean,
    path: string,
    sameParentPath: boolean,
  ) => {
    if (isMetaOrCtrlKeyPressed) {
      window.open(`${protocol}//${host}/${path}`, '_blank');
    } else if (sameParentPath) {
      history.push(`/${path}`);
    } else {
      (window as Window).location = `${protocol}//${host}/${path}`;
    }
  };

  const handleOnSelect = (
    isMetaOrCtrlKeyPressed: boolean,
    currentSelected: SelectedMenuItem,
  ) => {
    const { menuInformation: { value: currentValue } } = currentSelected;
    const { menuInformation: { value: previousValue } } = selectedMenuItem;
    const currentParentPath = currentValue.indexOf('/') !== -1 ? currentValue.substring(0, currentValue.indexOf('/')) : '';
    const previousParentPath = previousValue.indexOf('/') !== -1 ? previousValue.substring(0, previousValue.indexOf('/')) : '';
    const sameParentPath = currentParentPath !== '' && previousParentPath !== ''
      && currentParentPath === previousParentPath;
    openSelectedItem(isMetaOrCtrlKeyPressed, currentValue, sameParentPath);
    if (!isMetaOrCtrlKeyPressed) {
      setSelectedMenuItem({
        ...currentSelected,
      });
    }
  };

  const checkForPrompt = (
    event: { metaKey: boolean; ctrlKey: boolean; },
    selected: SelectedMenuItem,
  ) => {
    const isMetaOrCtrlKeyPressed = event.metaKey || event.ctrlKey;
    if (!isMetaOrCtrlKeyPressed && showPrompt) {
      showExitPrompt(() => handleOnSelect(isMetaOrCtrlKeyPressed, selected));
    } else {
      handleOnSelect(isMetaOrCtrlKeyPressed, selected);
    }
  };

  const getInitialSelectedMenuItem = () => {
    const flattenMenuItems: Array<SelectedMenuItem> = [];

    menuItems.forEach((menuItem, dropdownIndex) => {
      const { subMenuItems = [] } = menuItem;
      if (subMenuItems.length === 0) {
        flattenMenuItems.push({
          ...menuItem,
          dropdownIndex,
        });
      } else {
        subMenuItems.forEach((subMenuItem: SelectedMenuItem, menuItemIndex: number) => {
          flattenMenuItems.push({
            ...subMenuItem,
            menuItemIndex,
            dropdownIndex,
          });
        });
      }
    });

    return flattenMenuItems.find((menuItem) => {
      const { menuInformation: { value } } = menuItem;
      return href.startsWith(`${protocol}//${host}/${value}`);
    }) || { menuInformation: { value: '', label: '' } };
  };

  const getSourceUrl = () => {
    if (sourceUrl) {
      return sourceUrl;
    }
    if (isLocal) {
      return 'https://iptools.e3-np.tools.playstation.net/v2/api/menuItems';
    }
    return `${protocol}//${host}/v2/api/menuItems`;
  };

  useEffect(() => {
    setSelectedMenuItem(getInitialSelectedMenuItem());
  }, [rawMenuItems]);

  useEffect(() => {
    fetch(getSourceUrl(), {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((resp) => resp.json())
      .then((json) => setRawMenuItems(json))
      .catch(() => setRawMenuItems([]));
  }, [token]);

  return (
    <>
      <div
        className={`iptools-common-ui-header ${fixed ? 'fixed' : ''}`}
        data-testid="iptools-common-ui-header"
      >
        <LocalNavigation
          id="iptools-header"
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          onSelect={checkForPrompt}
          onToggle={() => {}}
        />
        {tooltip && (
          <div className="header-tooltip">
            <Tooltip
              content={<span className="tooltip-content">{tooltip}</span>}
              shortCopy
              anchor={headerTooltipWrapper}
            />
          </div>
        )}
      </div>
      <div
        className="header-tooltip-wrapper"
        ref={headerTooltipWrapper}
      />
    </>
  );
};

Header.displayName = 'Header';

export default Header;
