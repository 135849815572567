export const TIME_ZONES = [
  'UTC+00:00',
  'UTC+01:00',
  'UTC+02:00',
  'UTC+03:00',
  'UTC+03:30',
  'UTC+04:00',
  'UTC+05:00',
  'UTC+05:30',
  'UTC+06:00',
  'UTC+07:00',
  'UTC+08:00',
  'UTC+09:00',
  'UTC+09:30',
  'UTC+10:00',
  'UTC+11:00',
  'UTC+12:00',
  'UTC-11:00',
  'UTC-10:00',
  'UTC-09:00',
  'UTC-08:00',
  'UTC-07:00',
  'UTC-06:00',
  'UTC-05:00',
  'UTC-04:00',
  'UTC-03:30',
  'UTC-03:00',
  'UTC-01:00',
];

export const SUPPORTED_TIMEZONE_NAMES = [
  'America/New_York',
  'America/Toronto',
  'America/Mexico_City',
  'America/Santiago',
  'America/Argentina/Buenos_Aires',
  'America/Sao_Paulo',
  'America/Bogota',
  'America/Lima',
  'America/Panama',
  'America/La_Paz',
  'America/Costa_Rica',
  'America/Guayaquil',
  'America/Guatemala',
  'America/El_Salvador',
  'America/Tegucigalpa',
  'America/Asuncion',
  'America/Montevideo',
  'America/Managua',
  'Europe/London',
  'Europe/Dublin',
  'Atlantic/Reykjavik',
  'Europe/Lisbon',
  'Europe/Vienna',
  'Europe/Brussels',
  'Europe/Zagreb',
  'Europe/Prague',
  'Europe/Stockholm',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Budapest',
  'Europe/Rome',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Europe/Warsaw',
  'Europe/Bratislava',
  'Europe/Ljubljana',
  'Europe/Madrid',
  'Europe/Stockholm',
  'Europe/Zurich',
  'Africa/Johannesburg',
  'Asia/Jerusalem',
  'Asia/Beirut',
  'Europe/Sofia',
  'Asia/Nicosia',
  'Europe/Helsinki',
  'Europe/Athens',
  'Europe/Bucharest',
  'Asia/Istanbul',
  'Europe/Kiev',
  'Asia/Riyadh',
  'Asia/Kuwait',
  'Asia/Qatar',
  'Asia/Bahrain',
  'Europe/Moscow',
  'Asia/Dubai',
  'Asia/Muscat',
  'Asia/Kolkata',
  'Australia/Melbourne',
  'Pacific/Auckland',
  'Asia/Taipei',
  'Asia/Hong_Kong',
  'Asia/Singapore',
  'Asia/Kuala_Lumpur',
  'Asia/Jakarta',
  'Asia/Bangkok',
  'Asia/Shanghai',
  'Asia/Seoul',
  'Asia/Tokyo',
];

export const DEFAULT_TIME_ZONE = 'UTC+00:00';
