import * as React from 'react';
import {
  MODAL_ACTIONS, Modal, ModalContext,
} from '@partner-global-ui/components';
import { CommonModalProps } from './CommonModalProps';

const { useEffect, useContext } = React;

const CommonModalRunner: React.FunctionComponent<CommonModalProps> = ({
  id,
  className,
  show,
  onClose,
  title,
  onSecondaryClick,
  onPrimaryClick,
  primaryLabel,
  primaryDisabled,
  secondaryLabel,
  secondaryDisabled,
  content,
  cancelLabel,
  hideCancelButton,
  height,
  width,
  dependencies = [],
}) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    if (show) {
      modalDispatch({
        type: MODAL_ACTIONS.SHOW,
        payload: (
          <Modal
            id={id}
            className={className}
            title={title}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            onPrimary={onPrimaryClick}
            onSecondary={onSecondaryClick}
            onClose={onClose}
            height={height}
            width={width}
            content={content}
            hideCancelButton={hideCancelButton}
            primaryDisabled={primaryDisabled}
            secondaryDisabled={secondaryDisabled}
            cancelLabel={cancelLabel}
          />
        ),
      });
    } else {
      modalDispatch({
        type: MODAL_ACTIONS.HIDE,
      });
    }
  }, [show, onPrimaryClick, onSecondaryClick, ...dependencies]);

  return null;
};

export default CommonModalRunner;
