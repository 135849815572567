import * as React from 'react';
import {
  Checkbox, Icon, Layout, Pill,
} from '@partner-global-ui/components';
import './NestedCheckBox.scss';

const { useState, useEffect } = React;

const DEFAULT_CHILDREN_WRAPPING_THRESHOLD = 15;
const DEFAULT_CHILDREN_SHOWING_THRESHOLD = 1;
export interface NestedCheckBoxProps {
  /** The ID of the component */
  id?: string,
  /** All available options for rendering the nested checkbox component */
  options: Array<Option>,
  /** The values that should be selected in the component */
  selectedValues: Array<string>,
  /** It returns the current selected values when any checkboxes are changed */
  onSelect?: (data: Array<string>) => void,
  /** The threshold at which the top level option should start wrapping to the next column.
   * By default, they will wrap to next column after 1 item,
   * meaning each option renders as its own column. */
  wrappingThreshold?: number;
}

export interface Option {
  /** The label of the checkbox */
  label: string;
  /** The value of the checkbox */
  value: string;
  /** If it's set to true, the checkbox will be disabled */
  disabled?: boolean;
  /** If it's set to true, the checkbox will support expanding/collapsing its children */
  expandable?: boolean;
  /** If it's set to true, the checkbox will be rendered as expanded by default
   *  if it has any children */
  expanded?: boolean;
  /** The children checkboxes */
  children?: Option[];
  /** Show the number of selected checkboxes (including all nested children) */
  showCount?: boolean;
  /** The threshold at which the children should start wrapping to the next column.
   * By default, all items will wrap to next column after 15 items. */
  childrenWrappingThreshold?: number;
  /** Show children only when the number of children is greater than the provided threshold
   * By default, it only shows children when it has the option has more than 1 child. */
  childrenShowingThreshold?: number;
}

const NestedCheckBox: React.FunctionComponent<NestedCheckBoxProps> = ({
  id = '',
  options = [],
  selectedValues = [],
  onSelect = () => {},
  wrappingThreshold = 1,
}) => {
  const [optionsToggle, setOptionsToggle] = useState<{ [key: string]: boolean }>({});
  const [localSelectedValues, setLocalSelectedValues] = useState<Set<string>>(new Set<string>());

  const getAllOptionValues = (option: Option) => {
    let values: string[] = [];
    if (option.children && option.children.length > 0) {
      option.children.forEach((child) => {
        values = [...values, ...getAllOptionValues(child)];
      });
    } else {
      values = [option.value];
    }
    return values;
  };

  const isValueSelected = (value: string) => localSelectedValues?.has(value);

  const allValuesSelected = (option: Option) => {
    const allValues = getAllOptionValues(option);
    return allValues.every((val) => isValueSelected(val));
  };

  const someValuesSelected = (option: Option) => {
    const allValues = getAllOptionValues(option);
    return allValues.some((value) => isValueSelected(value));
  };

  const handleOnClickOption = (option: Option) => {
    const newSelectedValues: Set<string> = new Set<string>(localSelectedValues);
    const intermediate = someValuesSelected(option) && !allValuesSelected(option);
    const allValues = getAllOptionValues(option);
    allValues.forEach((opt) => {
      if (!newSelectedValues.has(opt)) {
        newSelectedValues.add(opt);
      } else if (!intermediate) {
        newSelectedValues.delete(opt);
      }
    });
    onSelect(Array.from(newSelectedValues).sort());
    setLocalSelectedValues(newSelectedValues);
  };

  const toggleOption = (option: Option) => {
    optionsToggle[option.value] = !optionsToggle[option.value];
    setOptionsToggle({ ...optionsToggle });
  };

  const renderOptionsArrow = (option: Option) => (
    (option.children && option.children.length > 1 && option.expandable) && (
      <button
        type="button"
        onClick={() => toggleOption(option)}
        data-testid={`option-toggle-${option.value}`}
      >
        <Icon>{optionsToggle[option.value] ? 'ico-down-chevron' : 'ico-right-chevron'}</Icon>
      </button>
    )
  );

  const getTotalCount = (option: Option) => {
    const allValues = getAllOptionValues(option);
    return allValues.filter((opt) => localSelectedValues?.has(opt)).length;
  };

  const renderOptionsCountPill = (option: Option) => (
    option.showCount && (
      <span
        className="options-count"
        data-testid={`option-count-${option.value}`}
      >
        <Pill
          id={`option-count-pill-${option.value}`}
          label={getTotalCount(option).toString()}
            // @ts-ignore
          status="error"
        />
      </span>
    )
  );

  const renderCheckbox = (option: Option) => {
    const isIndeterminate = someValuesSelected(option) && !allValuesSelected(option);
    const isChecked = allValuesSelected(option);
    return (
      <Checkbox
        id={`checkbox-${option.value}`}
        key={option.value}
        label={option.label}
        indeterminate={isIndeterminate}
        checked={isChecked}
        disabled={option.disabled}
        onClick={() => handleOnClickOption(option)}
        attr={{
          'data-testid': `checkbox-${option.value}`,
          'aria-checked': isIndeterminate ? 'mixed' : isChecked.toString(),
          // @ts-ignore this should be a boolean but ts-lint requires it to be a string
          checked: isChecked,
          // @ts-ignore this should be a boolean but ts-lint requires it to be a string
          readOnly: true,
        }}
      />
    );
  };

  const renderCheckboxContainer = (option: Option) => (
    <div className="checkbox-container" data-testid={`checkbox-container-${option.value}`}>
      {renderCheckbox(option)}
      {renderOptionsCountPill(option)}
      {renderOptionsArrow(option)}
    </div>
  );

  const renderOptions = (
    ops: Option[],
    childrenWrappingThreshold: number,
    parentOption: Option | null = null,
  ) => {
    if (!ops.length) {
      return null;
    }
    const columnCount = Math.ceil(ops.length / childrenWrappingThreshold);
    const columns: JSX.Element[] = [];
    for (let i = 0; i < columnCount; i++) {
      /** We push the items to each column up to the threshold */
      const columnItems: JSX.Element[] = [];
      for (let j = i * childrenWrappingThreshold;
        j < (i + 1) * childrenWrappingThreshold && j < ops.length;
        j++
      ) {
        const option = ops[j];
        /** Use the default threshold if none is provided */
        const childrenShowingThreshold = option.childrenShowingThreshold !== undefined
          ? option.childrenShowingThreshold
          : DEFAULT_CHILDREN_SHOWING_THRESHOLD;
        columnItems.push(
          <div
            className="column-item"
            key={`column-item-${option.value}`}
            data-testid={`column-item-${option.value}`}
          >
            {renderCheckboxContainer(option)}
            {(option.children && option.children.length > childrenShowingThreshold)
                && optionsToggle[option.value]
                && renderOptions(
                  option.children,
                  option.childrenWrappingThreshold || DEFAULT_CHILDREN_WRAPPING_THRESHOLD,
                  option,
                )}
          </div>,
        );
      }
      columns.push(
        <div
          className="column"
          key={`column-${i}-${parentOption ? `${parentOption.value}` : 'root'}`}
          data-testid={`column-${i}-${parentOption ? `${parentOption.value}` : 'root'}`}
        >
          {columnItems}
        </div>,
      );
    }
    return (
      <div
        className="columns-container"
        key={`options-container-${parentOption ? `${parentOption.value}` : 'root'}`}
        data-testid={`options-container-${parentOption ? `${parentOption.value}` : 'root'}`}
      >
        {columns}
      </div>
    );
  };

  const setDefaultToggleState = (ops: Option[], toggleState: { [key: string]: boolean }) => {
    ops.forEach((option: Option) => {
      if (option.expanded) {
        toggleState[option.value] = true;
      }
      if (option.children && option.children.length > 0) {
        setDefaultToggleState(option.children, toggleState);
      }
    });
  };

  useEffect(() => {
    setLocalSelectedValues(new Set<string>(selectedValues));
  }, [selectedValues]);

  useEffect(() => {
    const toggleState = { ...optionsToggle };
    setDefaultToggleState(options, toggleState);
    setOptionsToggle(toggleState);
  }, [options]);

  return (
    <Layout className="nested-checkbox-container" id={id}>
      {renderOptions(options, wrappingThreshold || DEFAULT_CHILDREN_WRAPPING_THRESHOLD)}
    </Layout>
  );
};

// Component name for use in static builds
NestedCheckBox.displayName = 'NestedCheckBox';

export default NestedCheckBox;
