import * as React from 'react';
import './NoPermissionsMessage.scss';
import { Icon } from '@partner-global-ui/components';

export interface NoPermissionsMessageProps {
  /** The message to be displayed */
  message: string,
}

const NoPermissionsMessage: React.FunctionComponent<NoPermissionsMessageProps> = ({
  message,
}) => (
  <div className="no-permissions-message" data-test-tag="no-permissions-message">
    <Icon>ico-warning</Icon>
    <p className="multiline-text">
      {message}
    </p>
  </div>
);

// Component name for use in static builds
NoPermissionsMessage.displayName = 'NoPermissionMessage';

export default NoPermissionsMessage;
