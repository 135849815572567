import * as React from 'react';
import cn from 'classnames';
import moment from 'moment';
import {
  TextInput, useClickOutside, Divider, createTestId, Button, Select, Radio, Icon, Tooltip,
} from '@partner-global-ui/components';
import { usePopper } from 'react-popper';
import { TIME_ZONES, DEFAULT_TIME_ZONE } from '../../constants/timezones';
import './TimePicker.scss';

const { useEffect, useRef, useState } = React;

export interface TimePickerProps {
  /* id of component */
  id?: string;
  /* name for TextInput */
  name?: string;
  /* label for TextInput */
  label?: string;
  /* is TextInput required */
  required?: boolean;
  /* placeholder for TextInput */
  placeholder?: string;
  /* value for TextInput */
  value?: string;
  /* default value for TextInput */
  defaultValue?: string;
  /* error message for TextInput */
  errorMessage?: string;
  /* error message for TextInput */
  hasError?: boolean;
  /* onFocus for TextInput */
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /* onBlur for TextInput */
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /* onChange for TextInput */
  onChange?: (e: {
    target: { timezone: any; name: string; id: string; value: any; isRollingTimezone: boolean }
  }) => void;
  /* disabled for TextInput */
  disabled?: boolean;
  /* momentjs time format */
  timeDisplayFormat?: string;
  /* momentjs timezone format */
  timezoneDisplayFormat?: string;
  /* momentjs timezone */
  timezone?: string;
  /* include or not include timezone calculations */
  disableTimezone?: boolean;
  /* disable timezone select options */
  disableTimeOptions?: boolean;
  /* boolean for rolling timezone */
  isRollingTimezone?: boolean;
  /* label for rolling timezone */
  rollingTimeLabel?: string;
  /* help link for rolling timezone label */
  helpLink?: string;
  /* label for global timezone */
  globalTimeLabel?: string;
  /* content in global timezone tooltip */
  globalTimeTooltip?: string;
  /* popper display format */
  popperDisplayFormat?: string;
}

const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  id = '',
  name = '',
  label = ' ',
  required = false,
  placeholder = '',
  value = '',
  defaultValue = '12:00 AM',
  timeDisplayFormat = 'hh:mm A',
  timezoneDisplayFormat = '[UTC]Z',
  popperDisplayFormat = 'hh:mm A',
  errorMessage = '',
  hasError = false,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  disabled = false,
  timezone = DEFAULT_TIME_ZONE,
  disableTimezone = false,
  disableTimeOptions = false,
  isRollingTimezone = false,
  rollingTimeLabel = 'Rolling Time',
  globalTimeLabel = 'Global Time',
  globalTimeTooltip = 'Global Time Tooltip',
  helpLink = '',
}) => {
  const [minuteValue, setMinuteValue] = useState<string | number>(0);
  const [hourValue, setHourValue] = useState<string | number>(0);
  const [timePickerValue, setTimePickerValue] = useState<string | null>(null);
  const [rollingTimezoneLocalValue, setRollingTimezoneLocalValue] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const timePickerRef = useRef<HTMLElement>(null);
  const hourSliderRef = useRef<HTMLInputElement>(null);
  const minuteSliderRef = useRef<HTMLInputElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [localValue, setLocalValue] = useState<string | null>(null);
  // local here means the local state:
  const [localStateTimezone, setLocalStateTimezone] = useState(timezone);

  const { styles, attributes, update } = usePopper(timePickerRef.current, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [25, 25] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });
  const getDisplayFormat = (rollingTimezone: boolean): string => (rollingTimezone ? timeDisplayFormat : `${timeDisplayFormat} ${timezoneDisplayFormat}`);
  const getPlaceholder = (): string => (placeholder || (isRollingTimezone ? defaultValue : `${defaultValue} ${DEFAULT_TIME_ZONE}`));

  const getMomentObjectByTimeValue = (
    timeValue: string | null,
    timeFormat: string = 'H:m',
    includesTimezone: boolean = false,
    strict: boolean = false,
    providedTimezone: string = localStateTimezone,
  ): moment.Moment | null => {
    if (!timeValue) {
      return null;
    }
    const dateFormat = 'MM/DD/YYYY';
    const dateTimeFormat = `MM/DD/YYYY ${timeFormat} ${!includesTimezone ? timezoneDisplayFormat : ''}`;
    const todayDate = moment(new Date()).format(dateFormat);
    const momentObject = moment(`${todayDate} ${timeValue} ${!includesTimezone ? providedTimezone : ''}`, dateTimeFormat, strict);

    if (includesTimezone && !disableTimezone) {
      momentObject.utcOffset(timeValue);
    } else {
      momentObject.utcOffset(providedTimezone);
    }
    return momentObject;
  };

  const setSliderPercentage = (inputElement: HTMLInputElement | null): void => {
    if (!inputElement) return;

    const percent = ((parseInt(inputElement.value, 10) - parseInt(inputElement.min, 10))
      / (parseInt(inputElement.max, 10) - parseInt(inputElement.min, 10))) * 100;
    inputElement.style.setProperty('--webkitProgressPercent', `${percent}%`);
  };

  const handleInput = (inputElement: HTMLInputElement): void => {
    let isChanging = false;

    const handleMove = () => {
      if (!isChanging) return;
      setSliderPercentage(inputElement);
    };
    const handleUpAndLeave = () => { isChanging = false; };
    const handleDown = () => { isChanging = true; };
    const handleClick = () => setSliderPercentage(inputElement);

    inputElement.addEventListener('mousemove', handleMove);
    inputElement.addEventListener('mousedown', handleDown);
    inputElement.addEventListener('mouseup', handleUpAndLeave);
    inputElement.addEventListener('mouseleave', handleUpAndLeave);
    inputElement.addEventListener('click', handleClick);

    setSliderPercentage(inputElement);
  };

  const handleIconClick = (): void => {
    setIsOpen(!isOpen);
    if (update) {
      update();
    }
  };

  const handleOnChange = (validValue: moment.Moment | null): void => {
    const localValueString = validValue?.isValid() ? validValue.format('H:m') : '';
    onChange({
      target: {
        id,
        name,
        value: localValueString,
        timezone: validValue?.isValid() ? validValue.format(timezoneDisplayFormat) : '',
        isRollingTimezone: rollingTimezoneLocalValue,
      },
    });
  };

  useEffect(() => {
    if (hourSliderRef.current) {
      handleInput(hourSliderRef.current);
    }
    if (minuteSliderRef.current) {
      handleInput(minuteSliderRef.current);
    }
  }, [hourSliderRef.current, minuteSliderRef.current]);

  useEffect(() => {
    const validValue = value
      ? getMomentObjectByTimeValue(`${value} ${timezone}`, `H:m ${timezoneDisplayFormat}`, !isRollingTimezone, false, timezone)
      : null;
    if (validValue?.isValid()) {
      setTimePickerValue(validValue.format(popperDisplayFormat));
      setLocalValue(validValue.format(getDisplayFormat(isRollingTimezone)));
    } else {
      setTimePickerValue(defaultValue);
      setLocalValue(null);
    }
  }, [value, timezone, isRollingTimezone]);

  useEffect(() => {
    if (isOpen) {
      const displayFormat = getDisplayFormat(isRollingTimezone);
      const validValue = getMomentObjectByTimeValue(localValue, displayFormat, !isRollingTimezone);
      if (validValue?.isValid()) {
        setHourValue(validValue.hours());
        setMinuteValue(validValue.minutes());
        setTimePickerValue(validValue.format(popperDisplayFormat));
      } else {
        setHourValue(0);
        setMinuteValue(0);
        setTimePickerValue(defaultValue);
      }
      setLocalStateTimezone(timezone || DEFAULT_TIME_ZONE);
      setRollingTimezoneLocalValue(isRollingTimezone);
    }
  }, [isOpen]);

  useEffect(() => {
    if (localValue === null) {
      return;
    }
    const displayFormat = getDisplayFormat(rollingTimezoneLocalValue);
    const validValue = getMomentObjectByTimeValue(
      localValue,
      displayFormat,
      !rollingTimezoneLocalValue,
      false,
      localStateTimezone,
    );
    handleOnChange(validValue);
  }, [localValue]);

  useEffect(() => {
    setLocalStateTimezone(timezone || DEFAULT_TIME_ZONE);
  }, [timezone]);

  useEffect(() => {
    setRollingTimezoneLocalValue(isRollingTimezone);
  }, [isRollingTimezone]);

  useEffect(() => {
    if (rollingTimezoneLocalValue) {
      setLocalStateTimezone(DEFAULT_TIME_ZONE);
    } else {
      setLocalStateTimezone(timezone || DEFAULT_TIME_ZONE);
    }
  }, [rollingTimezoneLocalValue]);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutside(componentRef, handleClickOutside);

  const handleLocalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFocus) {
      setIsFocus(true);
    }
    onFocus(e);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isFocus) {
      setIsFocus(false);
    }

    onBlur(e);
  };

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: newHourValue } } = event;
    setHourValue(newHourValue);
    const momentObject = getMomentObjectByTimeValue(`${newHourValue}:${minuteValue}`);
    setTimePickerValue(momentObject ? momentObject.format(popperDisplayFormat) : '');
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: newMinuteValue } } = event;
    setMinuteValue(newMinuteValue);
    const momentObject = getMomentObjectByTimeValue(`${hourValue}:${newMinuteValue}`);
    setTimePickerValue(momentObject ? momentObject.format(popperDisplayFormat) : '');
  };

  const handleTimeClearBtn = () => {
    setTimePickerValue(defaultValue);
    setLocalValue('');
    if (!disableTimezone) {
      setLocalStateTimezone(DEFAULT_TIME_ZONE);
    }
    setHourValue(0);
    setMinuteValue(0);
    setSliderPercentage(hourSliderRef.current);
    setSliderPercentage(minuteSliderRef.current);
    if (!disableTimeOptions) {
      setRollingTimezoneLocalValue(false);
    }
  };

  const handleTimeDoneBtn = () => {
    const validValue = getMomentObjectByTimeValue(`${hourValue}:${minuteValue}`);
    const nextLocal = validValue && validValue.format(getDisplayFormat(rollingTimezoneLocalValue));
    setLocalValue(nextLocal);
    setIsOpen(false);
  };

  const wrapperClasses = cn('time-picker-container', 'form-field', {
    active: isOpen || isFocus,
    disabled,
    'has-error': errorMessage || hasError,
  });
  const inputErrorMessage = errorMessage;

  const getSelectedTimezone = () => {
    if (rollingTimezoneLocalValue && disableTimezone) {
      return { label: DEFAULT_TIME_ZONE, value: DEFAULT_TIME_ZONE };
    }
    return { label: localStateTimezone, value: localStateTimezone };
  };

  const renderTimezonesDropdown = () => (
    <Select
      id={`${name}-timezones-select`}
      options={TIME_ZONES.map((timeZone) => ({ label: timeZone, value: timeZone }))}
      value={getSelectedTimezone()}
      onChange={(event?: { target?: { value?: { value: string } } }) => setLocalStateTimezone(event?.target?.value?.value || '')}
      disabled={disableTimezone || rollingTimezoneLocalValue}
    />
  );

  const toggleRollingTimezone = () => setRollingTimezoneLocalValue(!rollingTimezoneLocalValue);

  const renderTooltip = (tooltipContent: string) => (
    // @ts-ignore
    <Tooltip
      position="top"
      content={tooltipContent}
      strategy="absolute"
      id="tooltip-container"
    >
      <span className="time-option-tooltip">
        <Icon>ico-help-circle</Icon>
      </span>
    </Tooltip>
  );

  const renderHelpLink = () => (
    <a
      href={helpLink}
      target="_blank"
      rel="noreferrer noopener"
      className="whats-this-link"
      data-testid={createTestId('whats-this-link', id)}
    >
      What&apos;s This?
    </a>
  );

  const renderTimeOption = (
    radioId: string,
    radioLabel: string,
    radioChecked: boolean,
    helperContent: any,
  ) => (
    <div className="time-option-row">
      <Radio
        id={radioId}
        label={radioLabel}
        value={radioLabel}
        disabled={disableTimeOptions}
        checked={radioChecked}
        onChange={() => toggleRollingTimezone()}
      />
      {helperContent}
    </div>
  );

  const renderAllTimeOptions = () => (
    <>
      {renderTimeOption('rollingTimezone', rollingTimeLabel, rollingTimezoneLocalValue, renderHelpLink())}
      {renderTimeOption('globalTime', globalTimeLabel, !rollingTimezoneLocalValue, renderTooltip(globalTimeTooltip))}
    </>
  );

  const renderTimePicker = () => (
    <div
      className="time-picker-popper-wrapper"
      data-testid={createTestId('time-picker-wrapper', id)}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper} // eslint-disable-line react/jsx-props-no-spreading
    >
      <div
        className="time-picker-arrow"
        style={styles.arrow}
        ref={setArrowElement}
      />
      <div
        className="time-picker"
      >
        <div className="time-picker-value-container">
          <span>{timePickerValue}</span>
        </div>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="23"
            value={hourValue}
            className="slider"
            onChange={handleHourChange}
            ref={hourSliderRef}
            data-testid={createTestId('hour-slider', id)}
          />
        </div>
        <div className="slider-label-container">
          <span className="slider-min-value-label">12AM</span>
          <span className="slider-max-value-label">11PM</span>
        </div>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="59"
            value={minuteValue}
            className="slider"
            onChange={handleMinuteChange}
            ref={minuteSliderRef}
            data-testid={createTestId('minute-slider', id)}
          />
        </div>
        <div className="slider-label-container">
          <span className="slider-min-value-label">0 min</span>
          <span className="slider-max-value-label">59 min</span>
        </div>
        <div className="time-options-container">{renderAllTimeOptions()}</div>
        <div className="slider-time-zone-container">
          <span className="slider-time-zone-value">{renderTimezonesDropdown()}</span>
        </div>
        <Divider secondary />
        <div
          className="slider-buttons-container"
          data-testid={createTestId('slider-buttons-container')}
        >
          <Button
            link
            className="slider-cancel-btn"
            type="button"
            onClick={handleTimeClearBtn}
            data-testid={createTestId('slider-cancel-btn', id)}
          >
            Clear
          </Button>
          <Button
            size="sm"
            primary
            className="slider-done-btn"
            type="button"
            onClick={handleTimeDoneBtn}
            data-testid={createTestId('slider-done-btn', id)}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={wrapperClasses} ref={componentRef} data-testid={createTestId('time-wrapper', id)}>
      <TextInput
        id={id}
        name={name}
        label={label}
        icon="schedule"
        iconActive={isOpen}
        iconRef={timePickerRef}
        required={required}
        placeholder={getPlaceholder()}
        value={localValue || ''}
        hasError={hasError}
        errorMessage={inputErrorMessage}
        disabled={disabled}
        onIconClick={() => handleIconClick()}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleLocalValue}
      />
      {isOpen && renderTimePicker()}
    </div>
  );
};

export default TimePicker;
