import * as React from 'react';
import './MultiViewTable.scss';
import {
  DataTable,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Kabob,
} from '@partner-global-ui/components';

export interface PrimaryButton {
  /* text of primary button */
  label: string,
  /* ID for primary button */
  id: string,
  /* disabled attribute of primary button */
  disabled: boolean,
  /* click handler for primary button */
  onClick: () => void,
}

export interface DropdownOption {
  /* label for dropdown option */
  label: string,
  /* value for dropdown option */
  value: string,
}

export interface TableRow {
  /* table row can contain any number of objects using string as key */
  [key: string]: string
}

export interface TableColumn {
  /* label to render in column header */
  label: string,
  /* optional ID to use as part of data-testid. if not specified will default to key */
  cellId?: string,
  /* optional string to set alignment of cell (left or right, centered by default) */
  align?: string,
  /* optional render function for cell, if absent will print value as string */
  renderFn?: (arg0: TableRow) => React.ReactNode,
}

export interface View {
  /* label for view (appears next to table title) */
  label: string,
  /* optional attribute to disable view in dropdown */
  disabled?: boolean,
  /* optional method to call when changing view */
  onSelectView?: () => void,
  /* optional method to call when changing subview */
  onSelectSubView?: (arg0: string) => void,
  /* array of options for subview dropdown  */
  subViews: Array<DropdownOption>,
  /* column template for subview (% recommended, must add up to 100) */
  columnTemplate: string,
  /* optional text/JSX to render in left part of table footer */
  bottomLeftText?: React.ReactNode,
  /* optional text/JSX to render in right part of table footer */
  bottomRightText?: React.ReactNode,
  /* optional text/JSX to render in left part of table footer */
  columns: {
    /* table columns can contain any number of objects using string as key */
    [key: string]: TableColumn,
  },
}

export interface MultiViewTableConfig {
  /* array that contains table rows */
  rows: Array<TableRow>,
  /* main table title */
  title: string,
  /* ID to use as part of data-testid for table */
  tableId: string,
  /* ID to use as part of data-testid for row */
  rowId: string,
  /* unique ID to key off rows */
  rowKey: string,
  /* optional primary button to render at top right of table */
  primaryButton?: PrimaryButton,
  /* index in case of multiple tables on same page */
  index: number,
  /* optional attribute for loading animations */
  loading?: boolean,
  /* default table view */
  defaultView: string,
  /* text to display in case of empty table */
  emptyTableText: string,
  /* object containing one or more views with unique keys */
  views: {
    [key: string]: View,
  }
}

export interface MultiViewTableProps {
  config: MultiViewTableConfig,
}

const getSubTableHeight = (numRows: number) => {
  const isEmpty = numRows === 0;
  const rowsToRender = isEmpty ? 1 : numRows;
  const ROW_HEIGHT = isEmpty ? 82 : 41;
  const HEADER_HEIGHT = isEmpty ? 0 : 24;
  const H_SCROLL_HEIGHT = 0;
  const DIVIDER_HEIGHT = 0;
  return (rowsToRender * ROW_HEIGHT) + ((rowsToRender + 2) * DIVIDER_HEIGHT)
    + HEADER_HEIGHT + H_SCROLL_HEIGHT;
};

const MultiViewTable: React.FunctionComponent<MultiViewTableProps> = ({
  config,
}) => {
  const {
    rows = [],
    title,
    primaryButton,
    views,
    defaultView,
    emptyTableText,
    tableId,
    rowId,
    index,
    rowKey,
    loading,
  } = config;

  const { useState, useEffect } = React;

  const [subTableHeight, setSubTableHeight] = useState(`${getSubTableHeight(rows.length)}px`);
  const [initialLoad, setInitialLoad] = useState(true);

  // this useEffect is a hack to allow table to have unlimited rows without scroll
  // under the hood we use PUCL subtable, which has a hardcoded height of 415px
  useEffect(() => {
    const isTableLargerThanMaxHeight = getSubTableHeight(rows.length) > 415;
    if (isTableLargerThanMaxHeight || !initialLoad) {
      let newHeight = getSubTableHeight(rows.length);
      if (isTableLargerThanMaxHeight) newHeight -= 11;
      // @ts-ignore
      document.getElementById(`${tableId}-sub-table-${index}-subtable`).style.height = `${newHeight}px`;
      setSubTableHeight(`${newHeight}px`);
    }
    setInitialLoad(false);
  }, [rows.length]);

  const viewOptions = Object.keys(views)
    .map(v => ({ value: v, label: views[v].label, disabled: views[v].disabled }));
  const [activeView, setActiveView] = useState(viewOptions.find(vO => vO.value === defaultView) || { value: '', label: '' });
  const [activeSubView, setActiveSubView] = useState(views[activeView.value].subViews[0] || {});
  const {
    columns, columnTemplate, bottomLeftText, bottomRightText, subViews, onSelectView,
    onSelectSubView,
  } = views[activeView.value];

  useEffect(() => {
    if (typeof onSelectView !== 'undefined') onSelectView();
    setActiveSubView(subViews[0] || {});
  }, [activeView]);

  useEffect(() => {
    if (typeof onSelectSubView !== 'undefined') onSelectSubView(activeSubView.value);
  }, [activeSubView]);

  const renderTableHeader = () => {
    if (rows.length === 0) return <div />;
    return (
      <TableHeader
        id={`${tableId}-table-header`}
      >
        {Object.keys(columns).map(c => (
          <TableHeaderCell value={c} key={c}>{columns[c].label}</TableHeaderCell>
        ))}
      </TableHeader>
    );
  };

  const renderTableRows = () => {
    if (rows.length === 0) {
      return (
        <TableRow id={''}>
          <TableCell
            className="empty-table-cell"
            id={`${rowId}-empty-table-cell`}
          >
            {emptyTableText}
          </TableCell>
        </TableRow>
      );
    }
    return rows.map(r => (
      <TableRow
        id={`${rowId}-row-${r[rowKey]}`}
        key={r[rowKey]}
      >
        {Object.keys(columns).map((c) => {
          const { cellId, align } = columns[c];
          return (
            <TableCell
              className={`${rowId}-cell ${rowId}-${cellId || c} ${align ? `${align}-align` : ''}`}
              id={`${rowId}-${cellId || c}-${r[rowKey]}`}
              key={c}
            >
              {typeof columns[c].renderFn !== 'undefined' ? columns[c].renderFn?.call(0, r) : r[c]}
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  const subTable = (
    <DataTable
      subtable
      columnTemplate={rows.length ? columnTemplate : 'auto'}
      hasVerticalScroll={false}
      scrollPositions={{ scrollLeft: 0, scrollTop: 0 }}
      id={`${tableId}-sub-table-${index}`}
      loading={loading}
    >
      {renderTableHeader()}
      {renderTableRows()}
    </DataTable>
  );
  return (
    <div
      className="multi-view-table"
      data-testid={`${tableId}-table-container-${index}`}
    >
      <div
        className="multi-view-table-primary-bar"
      >
        <span
          className="multi-view-table-primary-title"
          data-testid={`${tableId}-table-${index}-title`}
        >
          {title}
        </span>
        {' - '}
        <span
          className="multi-view-table-primary-view"
          data-testid={`${tableId}-table-${index}-view`}
        >
          {activeView.label}
        </span>
        {viewOptions.length > 1 && (
          <Kabob
            // @ts-ignore
            menuItems={viewOptions.map(vO => ({
              ...vO,
              id: `${tableId}-table-${index}-view-dropdown`,
              disabled: vO.disabled || activeView.label === vO.label,
              onClick: () => {
                setActiveView(vO);
              },
              icon: '',
            }))}
            iconStr="ico-down-chevron"
            id={`${tableId}-table-${index}-view-dropdown`}
          />
        )}
        {primaryButton && (
          <button
            type="button"
            onClick={() => primaryButton.onClick()}
            className="multi-view-table-primary-button"
            disabled={primaryButton.disabled}
            data-testid={primaryButton.id}
          >
            {primaryButton.label}
          </button>
        )}
      </div>
      <div
        className="multi-view-table-secondary-bar"
      >
        <span
          className="multi-view-table-secondary-view"
          data-testid={`${tableId}-table-${index}-subview`}
        >
          {activeSubView.label}
        </span>
        {subViews.length > 0 && (
          <Kabob
            // @ts-ignore
            menuItems={subViews.map(sV => ({
              ...sV,
              id: `${tableId}-table-${index}-subview-dropdown`,
              onClick: () => {
                setActiveSubView(sV);
              },
            }))}
            iconStr="ico-down-chevron"
            id={`${tableId}-table-${index}-subview-dropdown`}
          />
        )}
      </div>
      <DataTable
        hasVerticalScroll={false}
        maxHeight={subTableHeight}
        className={rows.length === 0 ? 'empty-table' : ''}
        id={`${tableId}-table-${index}`}
        columnTemplate={''}
      >
        <TableRow
          expanded
          expandable
          expandedContent={subTable}
          children={null}
          id={`${tableId}-table-${index}-expanded-content`}
        />
      </DataTable>
      <div
        className="multi-view-table-footer"
      >
        <span
          className="bottom-left-text"
          data-testid={`${tableId}-table-${index}-footer-text-left`}
        >
          {bottomLeftText}
        </span>
        <span
          className="bottom-right-text"
          data-testid={`${tableId}-table-${index}-footer-text-right`}
        >
          {bottomRightText}
        </span>
      </div>
    </div>
  );
};

// Component name for use in static builds
MultiViewTable.displayName = 'MultiViewTable';

export default MultiViewTable;
