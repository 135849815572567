import * as React from 'react';
import moment from 'moment';
import './DateTimeRange.scss';

export interface DateTimeRangeProps {
  /** ID for component */
  id: string,
  /** Start date (in Unix format) to be displayed in component */
  startDate: string,
  /** End date (in Unix format) to be displayed in component */
  endDate: string,
  /** String for rendering Timezone, for example, "UTC+03:00" */
  timezone: string,
  /** Displays timezone as RTZ if true */
  isRollingTimezone: boolean,
  /** Displays End Date as Evergreen if true */
  alwaysOn: boolean,
}

const DateTimeRange: React.FC<DateTimeRangeProps> = ({
  id, startDate, endDate, timezone, isRollingTimezone, alwaysOn,
}) => {
  const renderDate = (date: string, isStartDate: boolean) => {
    const dateMoment = moment.utc(parseInt(date, 10));
    const getDataTestId = (str: string) => (isStartDate ? `${id}-start-${str}` : `${id}-end-${str}`);
    const renderDateLabel = () => (isStartDate ? 'Start' : 'End');
    const renderDateValue = () => {
      if (!isStartDate && alwaysOn) {
        return (
          <>
            <div
              className="month-date-image is-evergreen"
              data-testid="evergreen-icon"
            />
            <div className="datetime-value">
              <div className="time-value" data-testid={getDataTestId('time-value')}>
                Evergreen
              </div>
            </div>
          </>
        );
      }
      if (dateMoment.isValid()) {
        return (
          <>
            <div className="month-date-image">
              <div className="month-image-value" data-testid={getDataTestId('month-image-value')}>
                {dateMoment.utcOffset(timezone).format('MMM')}
              </div>
              <div className="date-image-value" data-testid={getDataTestId('date-image-value')}>
                {dateMoment.utcOffset(timezone).format('D')}
              </div>
            </div>
            <div className="datetime-value">
              <div className="date-value" data-testid={getDataTestId('date-value')}>
                {dateMoment.utcOffset(timezone).format('YYYY/MM/DD')}
              </div>
              <div className="time-value" data-testid={getDataTestId('time-value')}>
                {dateMoment.utcOffset(timezone).format(`hh:mm A ${isRollingTimezone ? 'RT[Z]' : timezone}`)}
              </div>
            </div>
          </>
        );
      }
      return 'None';
    };
    return (
      <>
        <div className="date-label" data-testid={getDataTestId('date-label')}>
          {renderDateLabel()}
        </div>
        <div className="datetime-value-container" data-testid={getDataTestId('datetime-container')}>
          {renderDateValue()}
        </div>
      </>
    );
  };

  return (
    <div className="date-time-range-container" id={id} data-testid={id}>
      <div className="date-time-range-date-container">{renderDate(startDate, true)}</div>
      <div className="date-time-range-date-container">{renderDate(endDate, false)}</div>
    </div>
  );
};

DateTimeRange.displayName = 'DateTimeRange';

export default DateTimeRange;
